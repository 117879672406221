import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Subscription, BehaviorSubject, interval } from "rxjs";

import { SortDirection } from "../../../directives/sortable.directive";

import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { SharedService } from "../../../services/shared.service";
import { UsersService } from "../../account-management/users/users.service";

import { Grid } from "../grid";
import { GridGroup } from "../grid-group";
import { User } from "src/app/models/shared";
import { TitleService } from "../../../services/title.service";
import { Widget } from "src/app/components/shared/new-details-page/widget-section/widget-section.component";
import { ZxGridComponent } from "src/app/components/shared/zx-grid/zx-grid.component";

interface State {
    page: number;
    pageSize: number;
    startNum: number;
    endNum: number;
    sortColumn: string;
    sortDirection: SortDirection;
}

@Component({
    selector: "app-grid-group-detail",
    templateUrl: "./grid-group-detail.component.html",
    styleUrls: ["../grids.component.scss"]
})
export class GridGroupDetailComponent implements OnInit, OnDestroy {
    user: User;

    id: number;
    gridGroup: GridGroup;
    loadingDetails = true;
    refreshing = false;

    isExpanded = false;
    fitToScreen = false;
    showTopBar = true;
    showBottomBar = true;

    firstLoad = true;
    cyclePagination: number;
    cyclePaginationInterval: number;

    gridCols: number;
    gridRows: number;
    tileHeight: number;
    gridHeight: number;

    allGrids: Grid[];
    mode: string;

    @ViewChild("gridGroupContent", { static: true }) gridGroupContent: ElementRef;

    private gridGroupSubscription: Subscription;
    private gridGroupRefreshSubscription: Subscription;
    private gridGroupCycleSubscription: Subscription;
    private userSubscription: Subscription;

    private gridsBS$ = new BehaviorSubject<Grid[]>([]);
    private totalBS$ = new BehaviorSubject<number>(0);
    private state: State = {
        page: 1,
        pageSize: 1,
        startNum: 0,
        endNum: 0,
        sortColumn: "",
        sortDirection: "asc"
    };
    protected gridGroupWidgets: Widget[] = [];
    scopeName: string;
    isLocked = false;
    gridGroupLoading = true;
    layoutChanging = false;
    layoutChanged = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private gs: GridsService,
        private userService: UsersService,
        private sharedService: SharedService,
        private titleService: TitleService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.route.paramMap.subscribe(params => {
            this.id = parseInt(params.get("id"), 10);
            this.scopeName = `gridGroupDetails${this.id}`;
            if (this.id) this.gridGroup = this.gs.getCachedGridGroup(this.id);

            // Loaded Details?
            if (!this.gridGroup || !this.gridGroup.hasFullDetails) this.loadingDetails = true;
            this.gs.getGridGroup(this.id, true);
        });
    }

    async ngOnInit() {
        this.userSubscription = this.userService.user.subscribe(user => {
            this.user = user;
        });

        this.isLocked = localStorage.getItem("isGridGroupLocked") === "true";

        this.gridGroupSubscription = this.gs.gridGroups.subscribe(gridGroups => {
            this.gridGroup = gridGroups.find((gg: GridGroup) => gg.id === this.id);
            const allGridsLayouts = this.gridGroup.layout_settings ? JSON.parse(this.gridGroup.layout_settings) : null;

            // Set Title
            this.titleService.setTitle("GRID_GROUP", "", this.gridGroup.name);
            //
            this.allGrids = this.gridGroup.grids;
            if (this.gridGroup && this.gridGroup.hasFullDetails) {
                this.loadingDetails = false;

                this.prepGridGroupData();

                // First Load
                if (this.firstLoad === true) {
                    this.firstLoad = false;

                    // Cycle Interval
                    if (!this.gridGroup.cycle_pagination_interval) this.cyclePaginationInterval = 15;
                    else this.cyclePaginationInterval = this.gridGroup.cycle_pagination_interval;
                    // Cycle Pagination
                    if (!this.gridGroup.cycle_pagination) this.cyclePagination = 0;
                    else {
                        this.cyclePagination = this.gridGroup.cycle_pagination;
                        this.startGridGroupCycle(this.cyclePaginationInterval);
                    }
                    // Settings
                    if (this.gridGroup.fit_screen) this.fitToScreen = true;
                    if (this.gridGroup.mode) this.mode = this.gridGroup.mode;
                }
            }
            if (!this.layoutChanging) {
                const isLayoutMatchGrids =
                    !!allGridsLayouts &&
                    this.allGrids.length === allGridsLayouts.length &&
                    this.allGrids.every(grid => allGridsLayouts.some(layout => layout.title === grid.name));
                if (isLayoutMatchGrids) {
                    this.buildWidgets(false, allGridsLayouts);
                } else {
                    this.resetLayout();
                }
            }
            this.gridGroupLoading = false;
        });
        this.changeDetectorRef.detectChanges();
    }

    resetLayout() {
        this.buildWidgets(true, null);
    }

    buildWidgets(isResetLayout: boolean, savedLayoutSettings) {
        this.gridGroupWidgets = this.allGrids?.map((grid, index) => {
            const layout =
                isResetLayout || !savedLayoutSettings[index]
                    ? Constants.defaultWidgetLayout
                    : savedLayoutSettings[index];

            return {
                ...{
                    title: grid.name,
                    id: grid.name,
                    index: index,
                    ...layout,
                    x: isResetLayout ? 0 : layout?.x ?? 0,
                    y: isResetLayout ? 0 : layout?.y ?? 0
                },
                component: ZxGridComponent,
                inputs: {
                    id: () => grid.id,
                    controls: () => true,
                    fitToScreen: () => this.fitToScreen,
                    rows: () => grid.rows
                }
            };
        });
    }

    async onWidgetsLayoutChange(widgets: Widget[]) {
        this.gridGroupWidgets = [...widgets];
        const layoutsArray = this.gridGroupWidgets.map(widget => {
            return {
                title: widget.title,
                id: widget.id,
                index: widget.index,
                isSelected: widget.isSelected,
                isHidden: widget.isHidden,
                minW: (widget as any).minW ?? 1,
                h: widget.h ?? 0,
                w: widget.w ?? 0,
                sizeToContent: (widget as any).sizeToContent ?? false,
                autoPosition: (widget as any).autoPosition ?? true,
                x: widget.x ?? 0,
                y: widget.y ?? 0
            };
        });

        const isLayoutChanged = this.gridGroup.layout_settings !== JSON.stringify(layoutsArray);
        this.layoutChanged = isLayoutChanged;
    }

    async saveLayout() {
        const layoutsArray = this.gridGroupWidgets.map(widget => {
            return {
                title: widget.title,
                id: widget.id,
                index: widget.index,
                isSelected: widget.isSelected,
                isHidden: widget.isHidden,
                minW: (widget as any).minW ?? 1,
                h: widget.h ?? 0,
                w: widget.w ?? 0,
                sizeToContent: (widget as any).sizeToContent ?? false,
                autoPosition: (widget as any).autoPosition ?? true,
                x: widget.x ?? 0,
                y: widget.y ?? 0
            };
        });

        this.layoutChanging = true;
        const layoutsToSave = {
            layout_settings: layoutsArray
        };
        await this.gs.updateGridGroup(this.gridGroup, layoutsToSave, true);
        this.layoutChanging = false;
    }

    lockChanged(isLocked: boolean) {
        this.isLocked = isLocked;
        localStorage.setItem("isGridGroupLocked", this.isLocked.toString());
    }

    ngOnDestroy() {
        this.gridGroupSubscription.unsubscribe();

        if (this.gridGroupCycleSubscription) this.stopGridGroupCycle();
        if (this.userSubscription) this.userSubscription.unsubscribe();
    }

    toggleTopBar() {
        this.showTopBar = !this.showTopBar;
    }

    toggleBottomBar() {
        this.showBottomBar = !this.showBottomBar;
    }

    startGridGroupRefresh() {
        this.gridGroupRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    stopGridGroupRefresh() {
        this.gridGroupRefreshSubscription.unsubscribe();
    }

    startGridGroupCycle(seconds: number) {
        this.gridGroupCycleSubscription = interval(seconds * 1000).subscribe(() => {
            this.page++;
            if (this.page > Math.ceil(this.allGrids.length / this.pageSize)) this.page = 1;
        });
    }

    stopGridGroupCycle() {
        this.gridGroupCycleSubscription.unsubscribe();
    }

    toggleGridCycle(seconds: number) {
        if (this.cyclePagination) {
            this.stopGridGroupCycle();
            this.cyclePagination = 0;
        } else {
            this.cyclePagination = 1;
            this.startGridGroupCycle(seconds);
        }
    }

    intervalChange() {
        if (this.gridGroupCycleSubscription) this.stopGridGroupCycle();
        if (this.cyclePagination) this.startGridGroupCycle(this.cyclePaginationInterval);
    }

    canEditGridGroup(gridGroup: GridGroup) {
        if (!this.user || !this.gridGroup) return false;
        return (
            ((this.user.is_admin || this.user.is_objects_manager) && gridGroup.public) ||
            this.user.id === gridGroup.user_id
        );
    }

    editGridGroup(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, id, "edit"]);
    }

    cloneGridGroup(id: number): void {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, id, "clone"]);
    }

    back() {
        this.router.navigate([Constants.urls.grids + "/" + Constants.urls.group_list]);
    }

    goToGrid(id: number) {
        const url = this.router.serializeUrl(
            this.router.createUrlTree([Constants.urls.grids + "/" + Constants.urls.grid, id])
        );

        window.open(url, "_blank");
    }

    goToLink(gridTitle) {
        const gridId = this.allGrids.find(grid => grid.name === gridTitle).id;
        this.goToGrid(gridId);
    }

    async refresh() {
        this.refreshing = true;
        await this.gs.getGridGroup(this.id, true).toPromise();
        this.refreshing = false;
    }

    expand() {
        this.isExpanded = true;
    }

    contract() {
        this.isExpanded = false;
    }

    get grids$() {
        return this.gridsBS$.asObservable();
    }
    get total$() {
        return this.totalBS$.asObservable();
    }
    get page() {
        return this.state.page;
    }
    set page(page: number) {
        this._set({ page });
    }
    get pageSize() {
        return this.state.pageSize;
    }
    set pageSize(pageSize: number) {
        this._set({ pageSize });
    }
    get startNum() {
        return this.state.startNum;
    }
    set startNum(startNum: number) {
        this._set({ startNum });
    }
    get endNum() {
        return this.state.endNum;
    }
    set endNum(endNum: number) {
        this._set({ endNum });
    }
    get sortColumn() {
        return this.state.sortColumn;
    }
    set sortColumn(sortColumn: string) {
        this._set({ sortColumn });
    }
    get sortDirection() {
        return this.state.sortDirection;
    }
    set sortDirection(sortDirection: SortDirection) {
        this._set({ sortDirection });
    }

    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepGridGroupData();
    }

    private prepGridGroupData() {
        const { sortColumn, sortDirection, pageSize, page } = this.state;

        // sort
        const listofGrids = this.sharedService.sort(this.gridGroup.grids, sortColumn, sortDirection);
        let grids = listofGrids;

        // size
        const total = listofGrids.length;

        // paginate
        grids = listofGrids.slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize);

        // page start & end
        this.state.startNum = page * pageSize - (pageSize - 1);
        this.state.endNum = Math.min(this.state.startNum + pageSize - 1, total);

        this.gridsBS$.next(grids);
        this.totalBS$.next(total);
    }

    // Identify items in dataCollection, it will not destroy and init again
    trackByFunction(index, item) {
        return item ? item.id : undefined;
    }
}
