import { Component, OnInit, inject } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { map, take } from "rxjs/operators";
import * as _ from "lodash";
import { Constants } from "../../../constants/constants";
import { GridsService } from "../grids.service";
import { UsersService } from "../../account-management/users/users.service";
import { GridGroup } from "../grid-group";
import { Grid } from "../grid";
import { SharedService } from "src/app/services/shared.service";
import { TitleService } from "../../../services/title.service";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { firstValueFrom } from "rxjs";

@Component({
    selector: "app-grid-group-form",
    templateUrl: "./grid-group-form.component.html"
})
export class GridGroupFormComponent implements OnInit {
    gridGroup: GridGroup;
    action: string;

    minLength = 2;
    isEdit = false;
    isClone = false;

    loading = true;
    saving = false;
    constants = Constants;

    privateGrids: Grid[];
    publicGrids: Grid[];

    private tourSteps = TourSteps.gridGroupForm;
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private location = inject(Location);
    private gs = inject(GridsService);
    private userService = inject(UsersService);
    private sharedService = inject(SharedService);
    private titleService = inject(TitleService);
    private tourService = inject(TourService);
    private mixpanelService = inject(MixpanelService);

    cantChangePublic$ = this.userService.getCurrentUser().pipe(
        take(1),
        map(user => !(user.is_admin || user.is_objects_manager || user.is_zixi_admin || user.is_zixi_support_write))
    );

    prepForm() {
        // Set Title
        this.titleService.setTitle("GRID_GROUP", this.action, this.gridGroup);

        if (this.action) {
            if (this.isClone) this.gridGroup.name = null;
            this.privateGrids = _.filter(this.gridGroup.grids, ["public", 0]);
            this.publicGrids = _.filter(this.gridGroup.grids, ["public", 1]);
        }

        if (!this.gridGroup && !this.isClone && !this.isEdit) {
            this.gridGroup = new GridGroup();
            this.gridGroup.mode = "stack";
            this.gridGroup.cycle_pagination_interval = 15;
        }
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        const gridGroupID = params.id;
        this.action = params.action;
        if (gridGroupID) {
            const parsedId = parseInt(gridGroupID, 10);
            this.gridGroup = Object.assign({}, this.gs.getCachedGridGroup(parsedId));

            // Check if grid group found in cache
            if (this.sharedService.isEmptyObject(this.gridGroup)) {
                await firstValueFrom(this.gs.refreshGridGroups(true).pipe(take(1)));
                this.gridGroup = Object.assign({}, this.gs.getCachedGridGroup(parsedId));
            }
        }

        if (this.action === "edit") this.isEdit = true;
        if (this.action === "clone") this.isClone = true;
        this.prepForm();
        this.tourService.initialize(this.tourSteps);
        this.loading = false;
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            name: this.gridGroup.name,
            public: this.gridGroup.public || false,
            grid_ids: [].concat(_.map(this.privateGrids, "id"), _.map(this.publicGrids, "id")),
            mode: this.gridGroup.mode,
            cycle_pagination: this.gridGroup.cycle_pagination,
            cycle_pagination_interval: this.gridGroup.cycle_pagination_interval
        };

        if (this.isEdit) {
            const result = await this.gs.updateGridGroup(this.gridGroup, model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, this.gridGroup.id]);
            } else this.saving = false;
        } else {
            const result = await this.gs.addGridGroup(model);
            if (result) {
                this.saving = false;
                this.router.navigate([Constants.urls.grids + "/" + Constants.urls.grid_group, result.id]);
            } else this.saving = false;
        }
    }

    cancel() {
        this.location.back();
    }

    // Error Code
    infoHasErrors(form: NgForm) {
        if (form.form.controls.name && form.form.controls.name.invalid === true) return true;
        else return false;
    }

    displayHasErrors(form: NgForm) {
        if (
            form.form.controls.cycle_pagination_interval &&
            form.form.controls.cycle_pagination_interval.invalid === true
        )
            return true;
        return false;
    }

    startTour() {
        this.tourService.start();
        this.mixpanelService.sendEvent("start create grid group feature guide");
    }
}
